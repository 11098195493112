import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import SEO from '../components/SEO';

import {
  PageWrapper,
  PageInner,
  PageTitle,
  PostLink,
  Banner,
  Grid2Col,
  Grid3Col,
  NavigationBottom,
  ArrowUpIcon,
  ArrowUpLink,

} from '../components/Elements';

const contactQuery = graphql`
  {
    banner: file(relativePath: { eq: "bandeau_contact.png" }) {
        childImageSharp {
            fluid( maxWidth:1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    plan: file(relativePath: { eq: "plan_acces.png" }) {
        childImageSharp {
            fluid(quality:100,  maxWidth:600) {
            ...GatsbyImageSharpFluid
          }
        }
    }

    page: datoCmsPageContact {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function SuccesPage() {
  const data = useStaticQuery(contactQuery);
  const { titre, contenu, seoMetaTags } = data.page;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <PageWrapper>
       <Banner>
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>
        <PageInner>
        <Grid3Col>
          <div>
              <PageTitle>{titre}</PageTitle>
              <div dangerouslySetInnerHTML={{ __html: contenu }} />
          </div>          
           
          <Img fluid={data.plan.childImageSharp.fluid}/>
                  
          <div>
            <h3>Message envoyé avec succès</h3>
            <p>Merci de nous avoir contacté, nous vous répondrons dans les plus brefs délais.</p>
          </div>

          </Grid3Col>
      {/*  <NavigationBottom>
          <ArrowUpLink to="/ressources" title="Ressources">Ressources<ArrowUpIcon title="Aller à la page Ressources" />
          </ArrowUpLink>
        </NavigationBottom>*/}
        </PageInner>
      </PageWrapper>
    </Fragment>
  );
}


